import {DrupalParagraph, DrupalTaxonomyTerm} from "next-drupal";
import {
  Dictionary,
  DrupalProductNode,
  DrupalSearchApiResponseMeta,
  IndustrySegmentsTaxonomyTerm
} from "../../../types/hygiena-types";
import {
  getIndustrySegmentProductParams,
  IndustrySegmentProductGrid
} from "../../molecules/industry-segment-product-grid";
import {useRouter} from "next/router";
import {LoadingSpinnerOverlay} from "../../atoms/loading-spinner";
import {useContext, createRef, useState} from "react";
import classNames from "classnames";
import dynamic from "next/dynamic";
import {DictionaryContext} from "../../../context/dictionary-context";

const IndustrySegmentSlider = dynamic(() => import("../../organisms/slider").then((mod) => mod.IndustrySegmentSlider));

/**
 * An industry segment slider that displays a production step product accordion when clicking a segment.
 *
 * @param paragraph
 * @constructor
 */
export function ParagraphIndustrySegmentSlider ({paragraph}: {paragraph: DrupalParagraph}) {
  const router = useRouter();
  const [activeSegment, setActiveSegment] = useState<IndustrySegmentsTaxonomyTerm>();
  const [activeSteps, setActiveSteps] = useState<DrupalTaxonomyTerm[]>();
  const [loading, setLoading] = useState<boolean>();
  const [products, setProducts] = useState<DrupalProductNode[]>(paragraph?.products ?? []);
  const [meta, setMeta] = useState<DrupalSearchApiResponseMeta>();
  const scrollRef = createRef();
  const industrySegments: IndustrySegmentsTaxonomyTerm[] = paragraph?.field_iss_industries ?? [];
  const steps: {[key: string]: DrupalTaxonomyTerm[]}[] = paragraph?.steps ?? {};
  const t = useContext<Dictionary>(DictionaryContext);

  async function searchIndustrySegments(id: string) {
    let productData: DrupalProductNode[];
    let productMeta: DrupalSearchApiResponseMeta;

    const params = getIndustrySegmentProductParams(id, 0, 6, router.locale ?? "en", router.defaultLocale ?? "en", {});
    try {
      const response = await fetch(`/api/industry-products?${params.getQueryString()}`, {
        method: 'POST',
        body: JSON.stringify({query: params.getQueryString(), locale: router.locale, defaultLocale: router.defaultLocale}),
      });
      const responseJson = await response.json();

      productData = responseJson?.products ?? [];
      productMeta = responseJson?.meta ?? {};
    }
    catch (error) {
      productData = products ?? [];
      productMeta = meta ?? {};
    }

    if (productMeta?.facets) {
      productMeta.facets = productMeta?.facets.sort((a, b) => {
        if (a?.path === "field_product_category_parent" || b?.label === "field_product_category_parent") return -1;
        return a?.label?.localeCompare(b?.label ?? "") ?? 0;
      });
    }
    setMeta(productMeta);
    setProducts(productData ?? []);
    setLoading(false);
  }

  /**
   * Callback for updating the selected industry.
   *
   * @param id
   *   The industry segment term id.
   */
  async function updateSelectedIndustry(id?: string) {
    if (id) {
      setLoading(true);
      setActiveSegment(industrySegments.filter(term => {
        if (term?.id === id) return term;
      })?.[0]);
      if (steps?.[id]) {
        setActiveSteps(steps[id]);
      }
      await searchIndustrySegments(id);
    }
  }

  if (!industrySegments?.length) return <></>
  return (
    <>
      <IndustrySegmentSlider terms={industrySegments} steps={steps} callback={updateSelectedIndustry} activeSegment={activeSegment} />
      {!activeSegment && (
        <div className="text-center pt-12 text-xl">{`${t?.all?.segment_slider_select}`}</div>
      )}
      <div className={classNames(
        "transition-opacity duration-500",
        {
          "opacity-1": activeSegment,
          "opacity-0": !activeSegment,
        }
      )}>
        <LoadingSpinnerOverlay loading={loading} fixed={true}/>
        {activeSegment && products?.length ? (
          <IndustrySegmentProductGrid key={`segment-slider-${activeSegment.id}`} term={activeSegment} products={products} meta={meta} gridKey={`industry-segment-product-grid-${activeSegment.id}`} scrollRef={scrollRef} steps={activeSteps}/>
        ): <></>}
      </div>
    </>
  );
}